export default {
  name: "canlender",
  data() {
    return {
      date: "",
      themeStyles: {
        wrapper: {
          background: "linear-gradient(to bottom right, #ff5050, #ff66b3)",
          color: "#fafafa"
        }
      }
    };
  },
  methods: {
    cancel() {
      this.$emit("cancle");
    },
    scheduleMessage() {
      this.$emit("scheduleMessage");
    }
  },
  computed: {
    currentLocale() {
      return this.$store.getters["language/currentLanguage"];
    }
  }
};