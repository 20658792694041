import store from '@/state/store.js';
export default {
  data() {
    return {
      mainDialogVisible: true
    };
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    dispatchShowFeedback() {
      store.dispatch('modal/showFeedback', true);
      store.dispatch('modal/showFeedbackModal', false);
      localStorage.setItem('askedForReview', 'false');
    },
    dispatchResetModal() {
      this.mainDialogVisible = false;
      store.dispatch('modal/showFeedbackModal', false).then(() => {
        this.mainDialogVisible = true;
        localStorage.setItem('askedForReview', 'false');
      });
    }
  },
  computed: {
    dynamicWidth() {
      if (screen.width < 768) {
        return '95%';
      } else {
        return '50%';
      }
    }
  }
};