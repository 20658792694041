<template>
  <div class="survey-container">
    <el-popover
      placement="left"
      width="1000"
      trigger="manual"
      :offset="300"
      v-model="surveyVisible">
      <Survey :survey="survey" class="survey-instance" />
      <el-button
        slot="reference"
        icon="el-icon-s-order"
        type="success"
        circle
        @click="surveyVisible = !surveyVisible"
        :title="'Take a survey'"
        v-if="!buttonVisible">
      </el-button>
    </el-popover>
  </div>
</template>

<script>
  import "survey-core/defaultV2.css";
  import { Model } from "survey-core";
  import { Survey } from "survey-vue-ui";
  import axios from "axios";
  import Storage from "../../../services/storage";
  import UserSatisfactionSurvey from "@/helpers/UserSatisfactionSurvey.json";
  export default {
    name: "npsSurvey",
    components: { Survey },

    data() {
      const survey = new Model(UserSatisfactionSurvey);
      survey.onComplete.add(this.submitSurveyResults);
      const storage = new Storage();
      return {
        survey,
        surveyVisible: false,
        storage,
        buttonVisible: false,
      };
    },
    methods: {
      async submitSurveyResults(sender) {
        const user = this.storage.getSavedState("currentUser");
        const results = sender.data;
        results.name = user.name;
        results.email = user.email;
        results.phone = user.phone;
        results.type = user.profile_type == 1 ? "Corporate" : "Individual";

        //  send to sheet DB api
        try {
          axios.post("https://sheetdb.io/api/v1/t2d789wv03bbx", results);
        } catch (error) {
          console.error(error);
        } finally {
          this.$mixpanel.track("NPS Survey Track", results);
          if (this.$store.getters["auth/currentUser"].country === "CI") {
            this.$mixpanelFranco.track("NPS Survey Track");
          }
          localStorage.setItem("surveyFilled", Math.floor(Date.now() / 1000));
          this.buttonVisible = true;
          this.surveyVisible = false;
          this.$notify({
            title: "Success",
            message: "Your response has been recorded successfully",
            type: "success",
          });
        }
      },
      checkEnabledAfterQuarter() {
        const lastClosed = localStorage.getItem("surveyFilled") || 0;
        if (lastClosed) {
          const currentTime = Math.floor(Date.now() / 1000);
          const quarterInSeconds = 3 * 30 * 24 * 60 * 60;
          if (currentTime - lastClosed < quarterInSeconds) {
            return false;
          }
        }
        return true;
      },
    },
    mounted() {
      this.buttonVisible = !this.checkEnabledAfterQuarter();
      this.surveyVisible = this.checkEnabledAfterQuarter();

      if (this.$store.getters["auth/currentUser"].profile_type === 0) {
        this.surveyVisible = false;
      }
    },
  };
</script>

<style scoped>
  button {
    width: 100%;
    height: 100%;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: fixed;
    right: 1.5rem;
    top: 80%;
    z-index: 1;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    font-size: 1rem;
    font-weight: 900;
  }

  .survey-instance {
    height: 500px;

    overflow: scroll;
  }

  .survey-instance >>> * {
    --primary: #fd8b1a !important;
  }

  .survey-instance >>> .sd-btn--action {
    line-height: 1.5;
    background-color: #fc8c30 !important;
  }

  @media screen and (max-width: 500px) {
    .survey-instance {
      max-width: 400px;
    }
  }
</style>
