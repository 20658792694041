var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('el-dialog', {
    attrs: {
      "visible": _vm.mainDialogVisible,
      "title": "Leave Feedback",
      "center": true,
      "width": _vm.dynamicWidth
    },
    on: {
      "update:visible": function ($event) {
        _vm.mainDialogVisible = $event;
      },
      "close": _vm.dispatchResetModal,
      "closed": _vm.dispatchResetModal
    }
  }, [_c('span', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.mainDialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dispatchShowFeedback
    }
  }, [_vm._v("Leave Feedback")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };